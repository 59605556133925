<template>
    <div class="relative">
        <div class="w-full absolute block z-20">
            <Header/>
        </div>
        <div class="space-top lbplus-legal-banner-container w-full z-25">
            <div class="relative h-full w-full flex justify-center items-start flex-col">
                <img class="absolute top-0 right-0 h-full object-cover z-5 lg:mr-20 2xl:mr-16 z-15" src="../../../assets/images/privacy-policy/lbplus-legal-banner.png" alt="Legal Terms Banner">
                <div class="flex flex-col items-start mobileL:items-end text-left mobileL:text-right z-10 max-w-md lg:max-w-lg 2xl:max-w-3xl p-0 mobileL:p-4 mt-8 
                    ml-8 lg:ml-32 2xl:ml-52 mobileL:gap-8 2xl:gap-12 leading-snug">
                    <img class="w-6 hidden mobileL:inline-block right-0 mobileL:w-16 xl:w-20 2xl:w-24" src="../../../assets/images/home-page/flower.png" alt="Flower">
                    <h1 class="lbplus-legal-banner-title mobileS:text-2xl xs:text-3xl md:text-4xl lg:text-6xl 2xl:text-8xl text-white-1">
                        {{ titleText }}
                    </h1>
                    <p class="flex font-montserrat text-sm sm:text-base md:text-lg lg:text-2xl 2xl:text-3xl mobileL:pl-32 lg:pl-24 pl-0 pr-20 mobileL:pr-0 text-white-1">
                        We know some things are best kept private. At Lay Bare, 
                        we value your concerns about privacy and we want to make 
                        sure you feel comfortable using our website and application.
                    </p>
                </div>
            </div>
        </div>
        <div class="relative w-full legal-scrollable-height">
        <img class="absolute inset-0 w-full h-full object-cover filter grayscale-0" src="../../../assets/images/privacy-policy/lbplus-body-bg.png" alt="">
            <div class="relative mx-auto md:text-base md:grid md:grid-cols-6 lg:text-xl md:sticky h-screen">
            <div class="relative col-span-2 overflow-hidden">
                <div class="md:text-lg hidden md:grid legal-scrollable-height md:sticky md:overflow-y-scroll max-h-[47vh]">
                    <div class="md:mt-0 bg-green-12 z-10 font-semibold text-white-1 font-mermaid pl-6 pr-2">
                        <h2 class="mb-4 text-2xl lg:text-3xl pt-12">Table of Contents</h2>
                        <ol class="text-xl lg:text-2xl pl-10">
                            <li class="mb-5">
                                <a href="#privacy-notice" class="text-left cursor-pointer hover:text-green-3">Privacy Notice</a>   
                                <ol class="ml-6 xl:ml-8  list-inside space-y-4">
                                    <li class="list-item">
                                        <a href="#personal-data-and-other-information-we-collect" class="text-left cursor-pointer hover:text-green-3">
                                        Personal Data And Other Information We Collect
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#use-and-disclosure-of-personal-data" class="text-left cursor-pointer hover:text-green-3">
                                        Use And Disclosure Of Personal Data
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#international-data-transfers" class="text-left cursor-pointer hover:text-green-3">
                                        International Data Transfers
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#retention-and-disposal-of-your-personal-information" class="text-left cursor-pointer hover:text-green-3">
                                        Retention And Disposal Of Your Personal Information
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#your-rights" class="text-left cursor-pointer hover:text-green-3">
                                        Your Rights
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#protecting-your-information" class="text-left cursor-pointer hover:text-green-3">
                                        Protecting Your Information
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#third-party-websites" class="text-left cursor-pointer hover:text-green-3">
                                        Third Party Websites
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#cookies-and-similar-technologies" class="text-left cursor-pointer hover:text-green-3">
                                        Cookies And Similar Technologies
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#management-and-security" class="text-left cursor-pointer hover:text-green-3">
                                        Management And Security
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#our-data-protection-office" class="text-left cursor-pointer hover:text-green-3">
                                        Our Data Protection Office
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#updates-to-the-policy" class="text-left cursor-pointer hover:text-green-3">
                                        Updates To The Policy
                                        </a>
                                    </li>
                                </ol>
                            </li>
                            <li class="mb-5">
                                <a href="#privacy-policy" class="text-left cursor-pointer hover:text-green-3">Privacy Policy</a>
                                <ol class="ml-6 xl:ml-8  list-inside space-y-4">
                                    <li class=" list-inside">
                                        <a href="#why-we-process-your-data" class="text-left cursor-pointer hover:text-green-3">
                                        Why we process your data
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#limitation" class="text-left cursor-pointer hover:text-green-3">
                                        Limitation
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#integrity-and-confidentiality" class="text-left cursor-pointer hover:text-green-3">
                                        Integrity and Confidentiality
                                        </a>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <a href="#confidentiality-commitment" class="text-left cursor-pointer hover:text-green-3">Confidentiality Commitment</a>
                                <ol class="ml-6 xl:ml-8  list-inside space-y-4">
                                    <li class="list-item">
                                        <a href="#confidentiality-obligation" class="text-left cursor-pointer hover:text-green-3">
                                        Confidentiality Obligation
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#non-disclosure" class="text-left cursor-pointer hover:text-green-3">
                                        Non-Disclosure And Non-Use
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#safeguarding-measures" class="text-left cursor-pointer hover:text-green-3">
                                        Safeguarding Measures
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#third-party-disclosure" class="text-left cursor-pointer hover:text-green-3">
                                        Third-Party Disclosure
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#limitations" class="text-left cursor-pointer hover:text-green-3">
                                        Limitations
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#integrity-confidentiality" class="text-left cursor-pointer hover:text-green-3">
                                        Integrity & Confidentiality
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#account-deletion" class="text-left cursor-pointer hover:text-green-3">
                                        Request for Account Deletion
                                        </a>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>

            <div class="mobileL:col-span-4 w-full legal-scrollable-height overflow-y-auto">
                <div class="md:text-xl block mobileL:hidden sticky -top-0.5">
                    <div class="md:mt-0 mb-4 bg-green-12 text-white-1 font-mermaid font-semibold">
                        <h2 class="mb-4 py-2 text-lg pl-4 md:text-3xl cursor-pointer sticky" @click="toggleAccordion">Table of Content
                            <span :class="{ 'transform rotate-90': isAccordionOpen, 'transform rotate-0': !isAccordionOpen }" class="ml-2 inline-block">&#9654;</span>
                        </h2>
                        <ol v-show="isAccordionOpen" class="h-100 pl-4 pb-2 overflow-y-auto">
                            <li class="mb-3">
                                <a href="#privacy-notice" class="text-left cursor-pointer">Privacy Notice</a>
                                <ol class="space-y-1 ml-4">
                                <li class="list-item">
                                    <a href="#personal-data-and-other-information-we-collect" class="text-left cursor-pointer hover:text-green-3 ">
                                    Personal Data And Other Information We Collect
                                    </a>
                                </li>
                                <li class="list-item">
                                    <a href="#use-and-disclosure-of-personal-data" class="text-left cursor-pointer hover:text-green-3">
                                    Use And Disclosure Of Personal Data
                                    </a>
                                </li>
                                <li class="list-item">
                                    <a href="#international-data-transfers" class="text-left cursor-pointer hover:text-green-3">
                                    International Data Transfers
                                    </a>
                                </li>
                                <li class="list-item">
                                    <a href="#retention-and-disposal-of-your-personal-information" class="text-left cursor-pointer hover:text-green-3 ">
                                    Retention And Disposal Of Your Personal Information
                                    </a>
                                </li>
                                <li class="list-item">
                                    <a href="#your-rights" class="text-left cursor-pointer ">
                                    Your Rights
                                    </a>
                                </li>
                                <li class="list-item">
                                    <a href="#protecting-your-information" class="text-left cursor-pointer hover:text-green-3 ">
                                    Protecting Your Information
                                    </a>
                                </li>
                                <li class="list-item">
                                    <a href="#third-party-websites" class="text-left cursor-pointer hover:text-green-3">
                                    Third Party Websites
                                    </a>
                                </li>
                                <li class="list-item">
                                    <a href="#cookies-and-similar-technologies" class="text-left cursor-pointer hover:text-green-3 ">
                                    Cookies And Similar Technologies
                                    </a>
                                </li>
                                <li class="list-item">
                                    <a href="#management-and-security" class="text-left cursor-pointer  hover:text-green-3 ">
                                    Management And Securiry
                                    </a>
                                </li>
                                <li class="list-item">
                                    <a href="#our-data-protection-office" class="text-left cursor-pointer hover:text-green-3 ">
                                    Our Data Protection Office
                                    </a>
                                </li>
                                <li class="list-item">
                                    <a href="#updates-to-the-policy" class="text-left cursor-pointer hover:text-green-3 ">
                                    Updates To The Policy
                                    </a>
                                </li>
                                </ol>
                            </li>
                            <li class="mb-3">
                                <a href="#privacy-policy" class="text-left cursor-pointer hover:text-green-3 ">Privacy Policy</a>
                                <ol class="ml-4 space-y-1">
                                    <li class="list-item">
                                        <a href="#why-we-process-your-data" class="text-left cursor-pointer hover:text-green-3 ">
                                        Why we process your data
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#limitation" class="text-left cursor-pointer hover:text-green-3 ">
                                        Limitation
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#integrity-and-confidentiality" class="text-left cursor-pointer  hover:text-green-3 ">
                                        Integrity and Confidentiality
                                        </a>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <a href="#confidentiality-commitment" class="text-left cursor-pointer hover:text-green-3 ">
                                    Confidentiality Commitment
                                </a>
                                <ol class="ml-4 space-y-1">
                                    <li class="list-item">
                                        <a href="#confidentiality-obligation" class="text-left cursor-pointer hover:text-green-3">
                                        Confidentiality Obligation
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#non-disclosure" class="text-left cursor-pointer hover:text-green-3">
                                        Non-Disclosure And Non-Use
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#safeguarding-measures" class="text-left cursor-pointer hover:text-green-3">
                                        Safeguarding Measures
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#third-party-disclosure" class="text-left cursor-pointer hover:text-green-3">
                                        Third-Party Disclosure
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#limitations" class="text-left cursor-pointer hover:text-green-3">
                                        Limitations
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#integrity-confidentiality" class="text-left cursor-pointer hover:text-green-3">
                                        Integrity & Confidentiality
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a href="#account-deletion" class="text-left cursor-pointer hover:text-green-3">
                                        Request for Account Deletion
                                        </a>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>

                <div class="w-full">
                    <div class="flex flex-col overflow-x-hidden">
                        <PrivacyNotice />
                        <div class="mobileL:border-t-2"></div>
                        <PrivacyPolicy/>
                        <div class="mobileL:border-t-2"></div>
                        <ConfidentialityCommitment />
                    </div>
                </div>
            </div>
        </div>
      </div>
        <div class="w-full absolute block z-20">
            <Footer/>
        </div>
  </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/LaybarePlusFooter.vue'
import PrivacyNotice from '../../../components/legal-terms/LBPlusPrivacyNotice.vue'
import PrivacyPolicy from '../../../components/legal-terms/LBPlusPrivacyPolicy.vue'
import ConfidentialityCommitment from '../../../components/legal-terms/LBPlusConfidentialityComm.vue'

export default {
    components: {
        Header,
        Footer,
        PrivacyNotice,
        PrivacyPolicy,
        ConfidentialityCommitment
    },
    mounted() {
        this.$gtag.pageview('/laybareplus/legal-terms'),
        window.addEventListener("resize", this.handleResize);
    },
    computed: {
        titleText() {
            return this.windowWidth < 480 ? "Legal Terms" : "LEGAL TERMS";
        },
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    },
    data() {
        return {
            isAccordionOpen: false,
            windowWidth: window.innerWidth,
            };
    },
    methods: {
        toggleAccordion() {
            this.isAccordionOpen = !this.isAccordionOpen;
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        scrollToTop() {
            document.body.scrollTo({ top: 0 })
        }
    },
}
</script>

<style>
.lbplus-legal-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #feb9c4 0%, #feacb8 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .lbplus-legal-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

.lbplus-legal-banner-title {
    color: #FFF;
    text-align: center;
    font-family: Mermaid;
    font-style: normal;
    font-weight: 600;
}

@media (min-width: 1536px) {
    .lbplus-legal-banner-container {
      height: 110vh;
    }
    .space-top {
      margin-top: 128px;
    }
}

@media (max-width:480px) {
    .lbplus-legal-banner-container{
    height:255px;
    background: linear-gradient(180deg, #f6c5c9 0%, #fcbfc6 100%);
    }
    .space-top {
    margin-top: 60px;
    }
}

.legal-scrollable-height {
  height: 83vh;
  width: fit-content;
}

@media (min-width: 1500px) and (max-height: 1500px) {
    .legal-scrollable-height {
        height: 85;
        width: fit-content;
    }
}

::-webkit-scrollbar {
  width: 0; 
}

</style>
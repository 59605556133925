<template>
    <div class="mt-8 text-green-12 font-mermaid px-6">
    <h2 class="text-2xl mobileL:text-3xl mb-4 font-semibold" id="confidentiality-commitment">Confidentiality Commitment</h2>
    <p class="text-base lg:text-2xl mb-8">
        At Lay Bare, we value your privacy concerns and want to ensure you feel comfortable using our websites and applications. 
    </p>
    <p class="text-base lg:text-2xl mb-8">
        We affirm our commitment to maintaining the confidentiality of the information we share. We understand the importance of
        safeguarding sensitive and proprietary information and assure you that all necessary measures are taken to preserve its privacy.
    </p>

    <h2 class="text-2xl mobileL:text-3xl mb-4 font-semibold" id="confidentiality-obligation">Confidentiality Obligation</h2>
    <p class="text-base lg:text-2xl mb-8">
        We acknowledge that any information provided by you or obtained shall be treated as confidential. This includes but is not limited to:
    </p>
    <ul class="list-disc mb-8 text-base lg:text-2xl">
        <li>
            Personal Information, such as your name, gender, civil status, date of birth, address, telephone or mobile numbers, email address,
            mailing address, proof of identification, and any other information relating to you that you have provided us in the submission of
            any form as a result of our interaction with you.
        </li>
        <li>
            Sensitive Information such as race, ethnic origin, marital status, age, color, and religion,  for diagnosis or treatment of your skin
            or aesthetic condition, quality improvement, coordination of care, training, and research.
        </li>
        <li>
            Information about your visit and use of our websites, digital platforms, and mobile apps, including but not limited to social media profile
            information, IP Addresses, your browsing behavior within and throughout our digital assets, and session lengths, are collected by our website
            analytics tools and cookies that we may place on your computer. You may turn off cookies on your browser at any time.
        </li>
    </ul>
    
    <h2 class="text-2xl mobileL:text-3xl mb-4 font-semibold" id="non-disclosure">Non-Disclosure And Non-Use</h2>
    <p class="text-base lg:text-2xl mb-8">
        We commit not to disclose, distribute or use any confidential information provided by you for any purpose unless we have obtained your prior
        written consent or as required by law. We will exercise due care and diligence in protecting confidentiality and preventing unauthorized access to the information.
    </p>

    <h2 class="text-2xl mobileL:text-3xl mb-4 font-semibold" id="safeguarding-measures">Safeguarding Measures</h2>
    <p class="text-base lg:text-2xl mb-8">
        We will implement appropriate technical, physical, and administrative measures to enhance confidential information's confidentiality, integrity, and security.
        These measures may include access controls, encryption firewalls, employee training, and other industry-standard security practices.
    </p>

    <h2 class="text-2xl mobileL:text-3xl mb-4 font-semibold" id="third-party-disclosure">Third-Party Disclosure</h2>
    <p class="text-base lg:text-2xl mb-8">
        We will not disclose confidential information to third parties without your explicit consent, except when necessary for the agreed-upon professional services.
        In such cases, we will ensure that any third party maintains similar confidentiality and information protection.
    </p>

    <h2 class="text-2xl mobileL:text-3xl mb-4 font-semibold" id="limitations">Limitations</h2>
    <p class="text-base lg:text-2xl mb-8">
        Lay Bare collects, uses, and discloses your Personal Information for the following purposes:
    </p>
    <ul class="list-disc mb-8 text-base lg:text-2xl">
        <li>
            To provide you with the products and services that you have requested.
        </li>
        <li>
            Enhance your customer experience by delivering product services matching your preference and needs.
        </li>
        <li>
            To allow you to access the features of the services offered.
        </li>
        <li>
            To improve and customize your user experience.
        </li>
        <li>
            To communicate relevant information, including responses to your queries, requests, and complaints.
        </li>
        <li>
            To store the same in Lay Bare’s services for its retention period.  
        </li>
        <li>
            To provide you with information about our products and services which may interest you.
        </li>
        <li>
            To comply with the requirements of the law, legal proceedings such as court orders and comply with a legal obligation.
            Prevent imminent harm to public security safety or order.
        </li>
        <li>
            To prevent, detect, and investigate possible violations of Lay Bare rules and regulations, which may include violations of
            Philippine Law, and to manage and mitigate risks to prevent the same.
        </li>
        <li>
            To process information for statistical, analytical, and research purposes.
        </li>
    </ul>

    <h2 class="text-2xl mobileL:text-3xl mb-4 font-semibold" id="integrity-confidentiality">Integrity & Confidentiality</h2>
    <p class="text-base lg:text-2xl mb-8">
        We take reasonable and suitable measures, both organizational and technical, to process your data to ensure its protection against
        unauthorized processing, accidental loss, alteration, or damage.
    </p>
    <p class="text-base lg:text-2xl mb-8">
        We understand that any breach of this confidentiality commitment may have severe legal and reputational consequences. Therefore,
        we assure you that we will exercise the highest care and responsibility in handling the confidential information entrusted to us.
    </p>
    <p class="text-base lg:text-2xl mb-8">
        If you have any questions or concerns regarding this confidentiality commitment or require further clarification, please do not hesitate to contact us.
    </p>
    <p class="text-base lg:text-2xl mb-8">
        Thank you for your trust in our brand and services. We look forward to serving you while upholding the principles of confidentiality and professionalism.
    </p>

    <h2 class="text-2xl md:text-3xl mb-4 font-semibold" id="account-deletion">Request for Account Deletion</h2>
    <p class="text-base lg:text-2xl mb-8">
        <a class="underline" href="https://forms.gle/rR8kNzZKdyMBHH5R7" target="_blank">Google form</a> for account deletion
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>